import React, { Component } from "react";
import { CustomInput } from "../Form/Inputs/Inputs";
import { CustomButton } from "../Buttons/Button";
import { addMenusValidation } from "../../functions/validateFunctions";
import { addMenu, editMenu } from "../../functions/postFunctions";
import SearchableDropdown from "../Form/Dropdown/SearchableDropdown";

class EditMenu extends Component {
  state = {
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    position: null,
    currentCategory: { value: 0, label: "" },
    positionList: []
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  handleFileInput = event => {
    this.setState({ logo: [...event] });
  };

  handleSubmit = event => {
    event.preventDefault();
    const errors = addMenusValidation(this.state.name, this.state.position);
    const { name, currentCategory } = this.state;
    if (errors.length === 0) {
      editMenu(
        name,
        currentCategory.value,
        this.props.menuForEdit.id,
        this.props.token
      ).then(res => {
        if (res.success) {
          this.setState({ name: "", position: null }, () => {
            this.props.addMenusToList(res.menus);
          });
        } else {
          let responseErrors = [];
          Object.keys(res.errors).forEach(key => {
            responseErrors.push(res.errors[key]);
          });
          this.setState({ responseErrors });
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (
      prevState.responseErrors != this.state.responseErrors &&
      this.state.responseErrors.length > 0
    ) {
      this.setState({ showErrorPopup: true });
    }
    if (
      prevProps.menuForEdit !== this.props.menuForEdit &&
      this.props.menuForEdit
    ) {
      const positionList = this.props.positions;
      const currentCategory = positionList.find(
        e => e.value === this.props.menuForEdit.position
      );
      this.setState({
        name: this.props.menuForEdit.name,
        currentCategory,
        positionList
      });
    }
  }

  removePopup = () => {
    this.setState({ showErrorPopup: false, errors: [], responseErrors: [] });
  };

  render() {
    const { name, positionList } = this.state;
    const { visible, hideModal } = this.props;
    return (
      <div
        className={`addTeamModal d-flex justify-content-center align-items-center ${
          visible ? "visible" : ""
        }`}
      >
        <div
          className={` errorPopup ${
            this.state.showErrorPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-18 f-w-4 uppercase text-center">
              Problemi prilikom kreiranja menija
            </h3>
            {this.state.errors.length > 0 ? (
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="mt-30">
                {this.state.responseErrors.map(e => {
                  return e.map((el, index) => (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  ));
                })}
              </ul>
            )}
            <div className="button text-center mt-30">
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className="addTeamContent py-30 px-30">
          <h4 className="text-color-primary f-s-20 lh text-center mb-20">
            Izmjeni meni
          </h4>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label="Naziv"
                value={name}
                handleChange={this.handleInput}
                name="name"
                index={0}
                counter={false}
              />
            </div>
            <div className="col-md-6">
              <SearchableDropdown
                data={positionList}
                placeholder="Kategorija"
                name="currentCategory"
                handleChange={this.handleInput}
                value={this.state.currentCategory}
              />
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-lg-12 d-flex justify-content-center">
              <CustomButton
                className="mr-30"
                onClick={e => this.handleSubmit(e)}
              >
                Izmjeni meni
              </CustomButton>
              <CustomButton className="red" onClick={hideModal}>
                Odustani
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditMenu;
