import React, { Component } from "react";
import {
  addVideo,
  deleteTeam,
  deleteVideo,
  editVideo,
  getAllTeams,
  getAllVideos
} from "../../functions/postFunctions";
import { CustomButton } from "../../components/Buttons/Button";
import { CustomInput } from "../../components/Form/Inputs/Inputs";
import DatePicker from "react-date-picker";
import {
  dateLocalisation,
  dateLocalisationShort,
  formatDateForSafari
} from "../../functions/functions";
import { addVideoValidation } from "../../functions/validateFunctions";

class VideosPage extends Component {
  state = {
    videos: [],
    title: "",
    link: "",
    date: new Date(),
    showAddTeam: false,
    showEditTeam: false,
    teamForEdit: null,
    errors: [],
    showErrorPopup: false,
    responseErrors: [],
    addingVideo: false
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Video");
    getAllVideos().then(res => {
      if (res.success) {
        this.setState({ videos: res.videos });
      }
    });
  }

  handleDeletePage = id => {
    deleteVideo(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ videos: res.videos });
      }
    });
  };

  showModal = (name, id) => {
    let team;
    if (id) {
      const { teams } = this.state;
      team = teams.find(e => e.id === id);
    }
    this.setState({ [name]: true, teamForEdit: team });
  };

  hideModal = () => {
    this.setState({
      showAddTeam: false,
      showEditTeam: false,
      teamForEdit: null
    });
  };

  addTeamToList = team => {
    this.setState(prevState => ({
      teams: [...prevState.teams, team],
      showAddTeam: false
    }));
  };

  refreshList = () => {
    getAllTeams().then(res =>
      this.setState({
        teams: res.teams,
        showEditTeam: false,
        teamForEdit: null
      })
    );
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleInputMultiple = (name, value, index) => {
    this.setState(({ videos }) => ({
      videos: [
        ...videos.slice(0, index),
        {
          ...videos[index],
          [name]: value
        },
        ...videos.slice(index + 1)
      ]
    }));
  };

  handleAddVideo = () => {
    const { link, title, date } = this.state;
    const errors = addVideoValidation(this.state);
    if (errors.length === 0) {
      this.setState({ addingVideo: true });
      addVideo({ link, date, title }, this.props.token).then(res => {
        if (res.success) {
          this.setState({
            link: "",
            title: "",
            date: new Date(),
            videos: res.videos,
            addingVideo: false
          });
        } else {
          this.setState({
            responseErrors: res.errors,
            showErrorPopup: true,
            addingVideo: false
          });
        }
      });
    } else {
      this.setState({ errors, showErrorPopup: true, addingVideo: false });
    }
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false });
  };

  handleUpdateVideo = video_id => {
    const video = this.state.videos.find(e => e.id === video_id);
    const { id, title, date, link } = video;
    const errors = addVideoValidation(video);
    if (errors.length === 0) {
      editVideo(
        { id, title, date, link: `https://www.youtube.com/watch?v=${link}` },
        this.props.token
      ).then(res => {
        if (res.success) {
          this.setState({
            videos: res.videos
          });
        } else {
          this.setState({ responseErrors: res.errors, showErrorPopup: true });
        }
      });
    } else {
      this.setState({ errors, showErrorPopup: true });
    }
  };

  render() {
    const { videos, teamForEdit } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div
          className={` errorPopup ${
            this.state.showErrorPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-18 f-w-4 uppercase text-center">
              Problemi prilikom kreiranja tima
            </h3>
            {this.state.errors.length > 0 ? (
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="mt-30">
                {this.state.responseErrors.map(e => {
                  return e.map((el, index) => (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  ));
                })}
              </ul>
            )}
            <div className="button text-center mt-30">
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className="pageTop mb-40">
          {this.state.addingVideo ? (
            <div className="content py-20 px-10 d-flex justify-content-center flex-column">
              <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                Dodavanje videa
              </h3>
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-3">
                <CustomInput
                  label="Naslov"
                  value={this.state.title}
                  handleChange={this.handleInput}
                  name="title"
                  realTime
                />
              </div>
              <div className="col-md-3">
                <CustomInput
                  label="Link"
                  value={this.state.link}
                  handleChange={this.handleInput}
                  name="link"
                />
              </div>
              <div className="col-md-3">
                <DatePicker
                  onChange={e => this.handleInput("date", e, 0)}
                  value={this.state.date}
                  locale={"bs-BS"}
                />
              </div>
              <div className="col-md-3">
                <CustomButton onClick={this.handleAddVideo}>
                  Dodaj video
                </CustomButton>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          {videos.map((e, index) => {
            return (
              <div className="col-md-4 mt-30" key={index}>
                <iframe
                  width="100%"
                  height="300"
                  src={`https://www.youtube.com/embed/${e.link}`}
                />
                <div className="inputs mt-20">
                  <CustomInput
                    label="Naslov"
                    value={e.title}
                    handleChange={this.handleInputMultiple}
                    name="title"
                    index={index}
                  />
                  <CustomInput
                    label="Link"
                    value={`https://www.youtube.com/watch?v=${e.link}`}
                    handleChange={this.handleInputMultiple}
                    name="link"
                    className="mt-20"
                    index={index}
                  />
                  <DatePicker
                    onChange={e => this.handleInputMultiple("date", e, 0)}
                    value={formatDateForSafari(e.date)}
                    locale={"bs-BS"}
                    className="mt-20"
                  />
                </div>
                <div className="btns d-flex justify-content-center mt-10">
                  <CustomButton
                    onClick={() => this.handleUpdateVideo(e.id)}
                    className="mr-20"
                  >
                    Izmjeni
                  </CustomButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default VideosPage;
