import React, { Component } from "react";
import {
  deleteGame,
  deletePage,
  getAllGames
} from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import { dateLocalisation } from "../../functions/functions";

class GamesPage extends Component {
  state = {
    theads: [
      "Domaćin",
      "Rezultat",
      "Gost",
      "Vrijeme i datum",
      "Takmičenje",
      "Akcije"
    ],
    games: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Utakmice");
    getAllGames().then(res => {
      if (res.success) {
        this.setState({ games: res.games });
      } else {
        alert("Something went wrong!");
      }
    });
  }

  handleDeleteGame = id => {
    deleteGame(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ games: res.games });
      }
    });
  };

  render() {
    const { theads, games } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <LinkButton href="dodaj-utakmicu">Dodaj utakmicu</LinkButton>
        </div>
        <Table theads={theads}>
          {games.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <div className="wrapper d-flex align-items-center justify-content-end">
                    <span className="f-s-16 mr-10">{e.host_name}</span>
                    <img
                      src={server + e.host_logo}
                      alt={e.slug}
                      style={{ maxHeight: "50px" }}
                    />
                  </div>
                </td>
                {e.host_goals !== null ? (
                  <td className="td px-10">
                    <div className="wrapper d-flex justify-content-center align-items-center">
                      <span className="f-s-16">{e.host_goals}</span>
                      <span className="mr-10 ml-10 f-s-16">:</span>
                      <span className="f-s-16">{e.guest_goals}</span>
                    </div>
                  </td>
                ) : (
                  <td className="td px-10 ">
                    <div className="wrapper d-flex justify-content-center align-items-center">
                      <span className="f-s-16">Utakmica nije završena</span>
                    </div>
                  </td>
                )}
                <td className="td px-10">
                  <div className="wrapper d-flex align-items-center justify-content-start">
                    <img
                      src={server + e.guest_logo}
                      alt={e.slug}
                      style={{ maxHeight: "50px" }}
                    />
                    <span className="f-s-16 mr-10">{e.guest_name}</span>
                  </div>
                </td>

                <td className="td px-10">
                  <span className="f-s-16">{dateLocalisation(e.date)}</span>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.competition_name}</span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <LinkButton
                    href={`izmjeni-utakmicu/${e.id}`}
                    className="mr-20"
                  >
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeleteGame(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default GamesPage;
