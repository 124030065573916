import React, { Component } from "react";
import {
  addTeamToTables,
  deleteFromTable,
  getAllCompetitions,
  getAllTeams,
  getTable,
  updateTable
} from "../../functions/postFunctions";
import { CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";

import SearchableDropdown from "../../components/Form/Dropdown/SearchableDropdown";
import { CustomInput } from "../../components/Form/Inputs/Inputs";
import { TeamPlaceholder } from "../../components/icons/icons";

class TablesPage extends Component {
  state = {
    theads: ["#", "Tim", "UT.", "POB.", "NER.", "POR.", "GR", "BOD.", "Akcije"],
    competitions: [],
    showAddCompetition: false,
    showEditCompetition: false,
    competitionForEdit: null,
    teams: [],
    competition: 0,
    table: [],
    team: 0,
    showUploadPopup: false
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Takmičenja");
    getAllCompetitions().then(res => {
      const competitions = res.competitions.map(e => {
        return {
          value: e.id,
          label: e.name
        };
      });
      this.setState({ competitions });
    });

    getAllTeams().then(res => {
      if (res.success) {
        const teams = res.teams.map(e => {
          return {
            value: e.id,
            label: e.name
          };
        });
        this.setState({ teams });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.competition !== this.state.competition) {
      this.setState({ showUploadPopup: true, table: [] });
      getTable(this.state.competition).then(res => {
        if (res.success) {
          this.setState({ table: res.table, showUploadPopup: false });
        }
      });
    }
    if (prevState.table !== this.state.table) {
      const { table } = this.state;
      const teamsIDs = table.map(({ tid }) => tid);
      getAllTeams().then(res => {
        if (res.success) {
          const teams = res.teams.map(e => {
            return {
              value: e.id,
              label: e.name
            };
          });
          const filteredTeams = teams.filter(e => !teamsIDs.includes(e.value));
          this.setState({ teams: filteredTeams, team: 0 });
        }
      });
    }
  }

  handleDeletePage = id => {
    const { competition } = this.state;
    deleteFromTable(id, competition, this.props.token).then(res => {
      if (res.success) {
        this.setState({ table: res.table });
      }
    });
  };

  showModal = (name, id) => {
    let competition;
    if (id) {
      const { competitions } = this.state;
      competition = competitions.find(e => e.id === id);
    }
    this.setState({ [name]: true, competitionForEdit: competition });
  };

  hideModal = () => {
    this.setState({
      showAddCompetition: false,
      showEditCompetition: false,
      competitionForEdit: null
    });
  };

  addCompetitionToList = team => {
    this.setState(prevState => ({
      competitions: [...prevState.competitions, team],
      showAddCompetition: false
    }));
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleTableInput = (name, value, index) => {
    this.setState(({ table }) => ({
      table: [
        ...table.slice(0, index),
        {
          ...table[index],
          [name]: !isNaN(parseInt(value)) ? parseInt(value) : 0
        },
        ...table.slice(index + 1)
      ]
    }));
  };

  refreshList = () => {
    getAllCompetitions().then(res =>
      this.setState({
        competitions: res.competitions,
        showEditCompetition: false,
        competitionsForEdit: null
      })
    );
  };

  handleAddTeamToTable = () => {
    const { competition, team } = this.state;
    addTeamToTables(team, competition, this.props.token).then(res => {
      if (res.success) {
        this.setState({ table: res.table });
      }
    });
  };

  updateTeam = team_id => {
    const { table, competition } = this.state;
    const elem = table.find(e => e.id === team_id);

    const { id, games, won, drew, lost, gd, points } = elem;

    const data = {
      id,
      games,
      won,
      drew,
      lost,
      gd,
      points,
      competition_id: competition
    };
    this.setState({ showUploadPopup: true, table: [] });
    updateTable(data, this.props.token).then(res => {
      if (res.success) {
        this.setState({ table: res.table, showUploadPopup: false });
      }
    });
  };

  render() {
    const { theads, competitions, table, teams } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div
          className={`errorPopup ${
            this.state.showUploadPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-center flex-column">
            <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
              Učitavanje
            </h3>
            <div className="optimization loader d-flex justify-content-center align-items-center">
              <div className="fancy-spinner">
                <div className="ring" />
                <div className="ring" />
                <div className="dot" />
              </div>
            </div>
          </div>
        </div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-4">
              <SearchableDropdown
                data={competitions}
                placeholder="Izaberi takmičenje"
                name="competition"
                handleChange={this.handleInput}
              />
            </div>
          </div>
        </div>
        {this.state.competition !== 0 ? (
          <>
            <Table theads={theads}>
              {table.map((e, index) => {
                return (
                  <tr key={index}>
                    <td className="td px-10">
                      <span className="f-s-20">{index + 1}.</span>
                    </td>
                    <td className="td px-10" style={{ minWidth: "180px" }}>
                      <div className="wrapper d-flex align-items-center">
                        {e.logo !== "" ? (
                          <>
                            <img
                              src={`${server}/${e.logo}`}
                              alt={""}
                              style={{ maxHeight: "50px" }}
                            />
                            <span className="f-s-16 ml-20">{e.name}</span>
                          </>
                        ) : (
                          <>
                            <TeamPlaceholder className="fill" />
                            <span className="f-s-16 ml-20">{e.name}</span>
                          </>
                        )}
                      </div>
                    </td>
                    <td className="td px-10">
                      <CustomInput
                        value={table[index].games}
                        handleChange={this.handleTableInput}
                        name="games"
                        index={index}
                        type="number"
                      />
                    </td>
                    <td className="td px-10">
                      <CustomInput
                        value={table[index].won}
                        handleChange={this.handleTableInput}
                        name="won"
                        index={index}
                        type="number"
                      />
                    </td>
                    <td className="td px-10">
                      <CustomInput
                        value={table[index].drew}
                        handleChange={this.handleTableInput}
                        name="drew"
                        index={index}
                        type="number"
                      />
                    </td>
                    <td className="td px-10">
                      <CustomInput
                        value={table[index].lost}
                        handleChange={this.handleTableInput}
                        name="lost"
                        index={index}
                        type="number"
                      />
                    </td>
                    <td className="td px-10">
                      <CustomInput
                        value={table[index].gd}
                        handleChange={this.handleTableInput}
                        name="gd"
                        index={index}
                        type="number"
                      />
                    </td>
                    <td className="td px-10">
                      <CustomInput
                        value={table[index].points}
                        handleChange={this.handleTableInput}
                        name="points"
                        index={index}
                        type="number"
                      />
                    </td>
                    <td className="td px-10" style={{ minWidth: "230px" }}>
                      <CustomButton
                        onClick={() => this.updateTeam(e.id)}
                        className="mr-20"
                      >
                        Ažuriraj
                      </CustomButton>
                      <CustomButton
                        onClick={() => this.handleDeletePage(e.id)}
                        className="red"
                      >
                        Izbriši
                      </CustomButton>
                    </td>
                  </tr>
                );
              })}
            </Table>
            <div className="addTeam mt-40">
              <div className="row">
                <div className="col-12">
                  <h4 className="f-s-20 text-color-primary mb-10">Dodaj tim</h4>
                </div>
                <div className="col-md-4">
                  <SearchableDropdown
                    data={teams}
                    placeholder="Izaberi tim"
                    name="team"
                    handleChange={this.handleInput}
                  />
                </div>
                {this.state.team !== 0 ? (
                  <div className="col-md-4">
                    <CustomButton onClick={this.handleAddTeamToTable}>
                      Dodaj tim
                    </CustomButton>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TablesPage;
