import React, { Component } from "react";
import {
  deleteCompetition,
  getAllCompetitions
} from "../../functions/postFunctions";
import { CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import AddCompetition from "../../components/AddCompetition/AddCompetition";
import EditCompetition from "../../components/EditCompetition/EditCompetition";

class CompetitionsPage extends Component {
  state = {
    theads: ["Logo", "Ime", "Akcije"],
    competitions: [],
    showAddCompetition: false,
    showEditCompetition: false,
    competitionForEdit: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Takmičenja");
    getAllCompetitions().then(res =>
      this.setState({ competitions: res.competitions })
    );
  }

  handleDeletePage = id => {
    deleteCompetition(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ competitions: res.competitions });
      }
    });
  };

  showModal = (name, id) => {
    let competition;
    if (id) {
      const { competitions } = this.state;
      competition = competitions.find(e => e.id === id);
    }
    this.setState({ [name]: true, competitionForEdit: competition });
  };

  hideModal = () => {
    this.setState({
      showAddCompetition: false,
      showEditCompetition: false,
      competitionForEdit: null
    });
  };

  addCompetitionToList = team => {
    this.setState(prevState => ({
      competitions: [...prevState.competitions, team],
      showAddCompetition: false
    }));
  };

  refreshList = () => {
    getAllCompetitions().then(res =>
      this.setState({
        competitions: res.competitions,
        showEditCompetition: false,
        competitionsForEdit: null
      })
    );
  };

  render() {
    const { theads, competitions, competitionForEdit } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <CustomButton onClick={() => this.showModal("showAddCompetition")}>
            Dodaj takmičenje
          </CustomButton>
          <AddCompetition
            visible={this.state.showAddCompetition}
            hideModal={this.hideModal}
            token={this.props.token}
            addCompetitionToList={this.addCompetitionToList}
          />
          <EditCompetition
            visible={this.state.showEditCompetition}
            hideModal={this.hideModal}
            token={this.props.token}
            refreshList={this.refreshList}
            competition={competitionForEdit}
          />
        </div>
        <Table theads={theads}>
          {competitions.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10 lbg">
                  <div className="wrapper d-flex justify-content-center align-items-center">
                    {e.logo !== "" ? (
                      <img
                        src={`${server}/${e.logo}`}
                        alt={""}
                        style={{ maxHeight: "50px" }}
                      />
                    ) : (
                      <span className="f-s-24 text-color-white">X</span>
                    )}
                  </div>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.name}</span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <CustomButton
                    onClick={() => this.showModal("showEditCompetition", e.id)}
                    className="mr-20"
                  >
                    Izmjeni
                  </CustomButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default CompetitionsPage;
